import { Injectable } from '@angular/core';
import { CONSTANTS } from 'app/constants';
import { CompanyOutputDto } from 'app/shared/models/company.models';
import { Observable } from 'rxjs';
import { RestService } from './request.service';
import { tap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  // company$: BehaviorSubject<CompanyOutputDto | undefined> = new BehaviorSubject<
  //   CompanyOutputDto | undefined
  // >(undefined);

  constructor(private rest: RestService) {
    // if (localStorage.getItem(CONSTANTS.token)) {
    //   const jsonStr: string = localStorage.getItem(CONSTANTS.company) ?? '';
    //   if (jsonStr === '') {
    //     this.loadCompany();
    //   } else {
    //     const company = JSON.parse(jsonStr) as CompanyOutputDto;
    //     this.company$.next(company);
    //   }
    // }
  }

  loadCompany(): Observable<CompanyOutputDto> {
    return this.rest.get$<CompanyOutputDto>('/company/my')
      .pipe(
        tap((company) => {
          localStorage.setItem(CONSTANTS.company, JSON.stringify(company));
        })
      );

    // .subscribe((res) => {

    //   this.company$.next(res);
    // });
  }

  // getCompany(): Observable<CompanyOutputDto | undefined> {
  //
  //   return this.company$.asObservable();
  // }
}
