import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirmdialog.component.html',
  styleUrls: ['confirmdialog.component.scss'],
})
export class ConfirmDialogComponent {
  @Input() icon: string|undefined;


  @Input() title: string|undefined='';

  @Input() question: string='';

  @Input() confirmButton = 'Salva';
  @Input() cancelButton: string | undefined = 'Annulla';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    public dialog: MatDialog
  ) {}

  // onUndo(): void {
  //   this.dialogRef.close(false);
  // }

  onConfirm(): void {
    this.dialogRef.close(true);
  }


}
