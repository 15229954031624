/* eslint-disable no-undef */
import { Pipe, PipeTransform } from '@angular/core';
import { GoogleMapsUtility } from '../utility';

@Pipe({
  name: 'wktGooglemaps'
})

export class WktToGoogleMapsPipe implements PipeTransform {
  transform(value: string, ..._args: any[]): google.maps.LatLng  {
    if (value === '' ){
      return new google.maps.LatLng(0, 0);
    }
    return GoogleMapsUtility.fromWktPointToLatLng(value);
  }


}
