import { CoordinateDto, LocationDto, WayPointDto } from 'app/shared/models/travel.models';

export class GoogleMapsUtility{
  static fromWktPointToLatLng(wktPoint: string | undefined): google.maps.LatLng {
    if (wktPoint && wktPoint !== ''){
    const wktPointArray = wktPoint.split(' ');
    const lat = parseFloat(wktPointArray[2].replace('(', '').replace(')', ''));
    const lng = parseFloat(wktPointArray[1].replace('(', '').replace(')', ''));
    return new google.maps.LatLng(lat, lng);
    } else{
      return new google.maps.LatLng(0,0);
    }
  }

  static fromGooglePlaceToWkt(place: google.maps.places.PlaceResult): string {
    const lat = place.geometry?.location?.lat() ??0;
    const lng = place.geometry?.location?.lng() ??0;
    return `POINT (${lng} ${lat})`;
  }

  static fromGooglePlaceToLocationDto(place: google.maps.places.PlaceResult): LocationDto {
    return {
      point: GoogleMapsUtility.fromGooglePlaceToWkt(place),
      address: place.formatted_address,
    };
  }
  static getWktPoint(lat: number, lng: number): string{
    return `POINT (${lng} ${lat})`;
  }

  static toLocationDto(lat: number, lng: number, address: string): LocationDto | Omit<WayPointDto, 'timeInstant'>{
    return {
      point: GoogleMapsUtility.getWktPoint(lat, lng),
      address: address,
    };
  }

  static fromWktToCoordinateDto(wktPoint: string): CoordinateDto {
    const wktPointArray = wktPoint.split(' ');
    const latitude = parseFloat(wktPointArray[2].replace('(', '').replace(')', ''));
    const longitude = parseFloat(wktPointArray[1].replace('(', '').replace(')', ''));

    return {
      latitude,
      longitude,
    };
  }
}
