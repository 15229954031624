<h1 mat-dialog-title>Attenzione!</h1>
<div mat-dialog-content>
  <div class="row text-center">
    <div class="modal_body">
      
      <div>
        <p>{{testoFiglio}}</p>
      </div>
      <div>
        <button variant="closedialog" mat-dialog-button (click)="retry()" class="indietro-button">Torna indietro</button>
      </div>
    </div>
  </div>
</div>
