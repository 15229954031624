import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { ScegliDataOraComponent } from './scegli-data-ora.component';
import { TimepickerComponent } from '../timepicker/timepicker.component';

@NgModule({
  imports: [
    CommonModule,
    TimepickerComponent,
    MatDatepickerModule,
    MatIconModule,

  ],
  exports: [ScegliDataOraComponent],
  declarations: [ScegliDataOraComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' }
  ],
})
export class ScegliDataOraModule { }
