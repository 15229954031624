<div class="col-12 timepicker">
  <div class="row">
    <div class="column col-4">
      <div>
        <button mat-raised-button (click)="addOre()">
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
      </div>
      <div class="circle">
        <strong class="ore-minuti" (click)="chooseTime()">{{ this.time.ore | number:'2.0'}}</strong>
        <!--<input matInput type="text" maxlength="2" inputmode="numeric" digitOnly="[0-9]*" [(ngModel)]="ore">-->
      </div>
      <div>
        <button mat-raised-button (click)="removeOre()">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
      </div>
    </div>
    <div class="col-4">
      <span>:</span>
    </div>
    <div class="col-4 column">
      <div>
        <button mat-raised-button (click)="addMinuti()">
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
      </div>
      <div class="circle">
        <strong class="ore-minuti" (click)="chooseTime()">{{ this.time.minuti | number:'2.0'}}</strong>
        <!--<input matInput type="text" maxlength="2" inputmode="numeric" digitOnly="[0-9]*" [(ngModel)]="minuti">-->
      </div>
      <div>
        <button mat-raised-button (click)="removeMinuti()">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
