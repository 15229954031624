import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  showSuccess(message: string, title = 'Operazione completata'): void{
    this.toastr.success(message, title);
  }

  showError(message: string, title='Operazione Fallita'): void{
    this.toastr.error(message, title);
  }

}
