import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AcceptedClientDocumentsOutputDto } from 'app/shared/models/document.model';


export const policyDocumentsActions = createActionGroup({
  source: 'PolicyDocuments',
  events: {

    'Loading Documents': emptyProps(),
    'Loading Documents Completed': props<{ documents: AcceptedClientDocumentsOutputDto[] }>(),
    'Loading Documents Failed': emptyProps(),
    'Accept policy documents': props<{ documentsIds: number[] }>(),
    'Accept policy Failed': emptyProps(),
  }
});
