import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

export const LANGUAGEKEY = 'language';

@Injectable({
  providedIn: 'root'
})
export class LanguageSelectionService {

  constructor(private translatorService: TranslateService) {
    this.translatorService.addLangs(['it', 'en']);
    if (localStorage.getItem(LANGUAGEKEY) === null) {
      this.setLanguage('it');
    } else {
      this.setLanguage(localStorage.getItem(LANGUAGEKEY) as string);
    }

  }

  setLanguage(language: string): void {
    this.translatorService.use(language);
    localStorage.setItem(LANGUAGEKEY, language);
  }

  getLanguage(): string {
    return this.translatorService.currentLang;
  }

  getAvailableLanguage(): string[] {
    return this.translatorService.getLangs();
  }
}
