import {Injectable} from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DipendenteService } from '../../services/dipendente.service';
import { NotificationService } from '../../services/notification.service';
import { MezziService } from '../../services/mezzi.service';
import { generalActions } from './general.actions';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';




@Injectable()
export class GeneralEffects {

  loadEmployee$ = createEffect(
    () => this.actions$.pipe(
      ofType(generalActions.loadEmployee),
      mergeMap(() => this.dipendenteService.getDipendenteInfoFromServer()),
      switchMap((employee) => {
        if (employee) {
          if (employee.poolingPreference === undefined) {
            employee.poolingPreference = {
              transportTypes: [],
              withEmployeesOfOtherCompanies: false,
              withSmokersOffer: false,
              withSmokersRequest: false,
              withOnlyWoman: false,
            };
          }
          return [
            generalActions.loadedEmployee({ employee }),

            // genericActions.loadingClient(),
            // genericActions.loadingCompany(),
          ];
        } else {
          return [generalActions.loadingEmployeeFailed()];
        }
      }),
      catchError(() => [generalActions.loadingEmployeeFailed()])
    ),
    { dispatch: true }
  );

  updateProfile$ = createEffect(
    () => this.actions$.pipe(
      ofType(generalActions.updateProfile),
      mergeMap((action) => this.dipendenteService.updateAnagrafica(action.employee)),
      tap(() => this.notificationService.showSuccess('Dati anagrafici aggiornati con successo')),
      switchMap((update) => ([generalActions.updateProfileCompleted({ update })])),
      catchError(() => [generalActions.updateProfileFailed()])
    ), { dispatch: true }
  );

  addingMezzoToProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generalActions.addingMezzo),
      mergeMap((action) => this.mezziService.addMezzo(action.mezzo)),
      switchMap((mezzi) => {
        if (mezzi) {
          //const updatedMezzi = mezzi.filter(m => m.isDisabled === false);
          return [
            generalActions.updatedMezzi({ mezzi }),
            generalActions.loadEmployee(),
          ];
        } else {
          return [generalActions.addingMezzoFailed()];
        }
      }),
      catchError(() => [generalActions.addingMezzoFailed()])
    )
  );

  updatingEmployeePoolingPreferences$ = createEffect(
    () => this.actions$.pipe(
      ofType(generalActions.updatingEmployeePoolingPreferences),
      switchMap((action) => this.dipendenteService.updateEmployeePoolingPreferences(action.preferences)),
      tap(() => this.notificationService.showSuccess('Le modifiche effettuate non si rifletteranno sulle corse già create')),
      switchMap((preferences) => [generalActions.updatedEmployeePoolingPreferences({ preferences })]),
      catchError(() => [generalActions.updatingEmployeePoolingPreferencesFailed()])
    ), { dispatch: true });
  //
  // loadingClient$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(genericActions.loadingClient),
  //     mergeMap(() => this.clientService.getClientInfo()),
  //     switchMap((client) => {
  //       if (client) {
  //         return [genericActions.loadClientCompleted({ client })];
  //       } else {
  //         return [genericActions.loadClientFailed()];
  //       }
  //     })
  //   ), { dispatch: true });
  //
  // loadingCompany$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(genericActions.loadingCompany),
  //     mergeMap(() => this.companyService.loadCompany()),
  //     switchMap((company) => {
  //       if (company) {
  //         return [genericActions.loadCompanyCompleted({ company })];
  //       } else {
  //         return [genericActions.loadCompanyFailed()];
  //       }
  //     })
  //   ), { dispatch: true });
  //
  // loadingInstallationInfo$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(genericActions.loadingInstallationInfo),
  //     mergeMap((action) => this.clientService.loadInstallationInfo(action.code)),
  //     switchMap((installationInfo) => {
  //       if (installationInfo) {
  //         return [genericActions.loadingInstallationInfoCompleted({ installationInfo })];
  //       } else {
  //         return [genericActions.loadingInstallationInfoFailed()];
  //       }
  //     })
  //   ), { dispatch: true });


  constructor(private actions$: Actions,
              private dipendenteService: DipendenteService,
              private notificationService: NotificationService,
              private mezziService: MezziService) {
  }




}
