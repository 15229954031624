import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared/modals/config-dialog/confirmdialog.component';
import { MessageComponent } from 'app/shared/modals/message-dialog/message.component';
import { ModalExitComponent } from 'app/shared/modals/modal-exit/modal-exit.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalErrorComponent } from '../modals/modal-error/modal-error.component';
import { ModalYnComponent } from '../modals/modal-yn/modal-yn.component';


@Injectable({
  providedIn: 'root',
  
})
export class CommonDialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * Show a confirmation dialog, if yes or no type, observable will return true or false, otherwise will return true
   *
   * @param type
   * @param icon
   * @param question
   * @param confirmButtonText
   * @param cancelButtonText
   * @param title
   */

  showDialog(
    type: 'Confirm' | 'YesNo',
    icon: string = 'help',
    question: string,
    confirmButtonText: string = 'Si',
    cancelButtonText: string = 'No',
    title: string | undefined = undefined
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '490px',
      height: 'fit-container',
    });

    dialogRef.componentInstance.question = question;
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.confirmButton = confirmButtonText;
    if (type === 'Confirm') {
      dialogRef.componentInstance.cancelButton = undefined;
    } else {
      dialogRef.componentInstance.cancelButton = cancelButtonText;
    }

    dialogRef.componentInstance.icon = icon;

    return dialogRef.afterClosed().pipe(
      map((result) => {
        if (type === 'Confirm') {
          return true;
        } else {
          return result as boolean;
        }
      })
    );
  }

  showMessageModal(
    description: string,
    closeButton: string = 'chiudi',
    title: string | undefined = undefined
  ): Observable<boolean> {
    const confirmModal = this.dialog.open(MessageComponent, {
      width: '400px',
    });
    confirmModal.componentInstance.title = title;
    confirmModal.componentInstance.question = description;
    confirmModal.componentInstance.cancelButton = closeButton;
    return confirmModal.afterClosed().pipe();
  }

  showConfirmModal(title: string, question: string): Observable<boolean> {
    const confirmModal = this.dialog.open(ModalYnComponent, {
      width: '400px',
    });
    confirmModal.componentInstance.title = title;
    confirmModal.componentInstance.text = question;
    return confirmModal.afterClosed().pipe();
  }

  showErrorDialog(errorMessage: string): Observable<any> {
    const dialogRef = this.dialog.open(ModalErrorComponent, {
      width: '400px',
    });
    dialogRef.componentInstance.testoFiglio = errorMessage;
    return dialogRef.afterClosed();
  }

  showExitDialog(): Observable<boolean>{
    const dialogRef = this.dialog.open(ModalExitComponent, {
      width: '400px',
    });
    return dialogRef.afterClosed();
  }
}
