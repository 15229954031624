import { Injectable } from '@angular/core';
import { Client } from 'app/shared/models/client';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { ClientPublicInfoDto } from '../models/installationInfo.model';
import { RestService } from './request.service';

const CLIENT_URL = '/clients';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private installationInfo$: BehaviorSubject<ClientPublicInfoDto | undefined>;

  constructor(private restService: RestService) {
    this.installationInfo$ = new BehaviorSubject<ClientPublicInfoDto | undefined>(undefined);
  }

  getInstallationInfo(): Observable<ClientPublicInfoDto | undefined>{
    return this.installationInfo$.asObservable();
  }

  getClientInfo(): Observable<Client> {
    return this.restService
      .get$<Client>(`${CLIENT_URL}/user/my`, undefined, true, 'gateway')
      .pipe(first());
  }

  loadInstallationInfo(code: string): Observable<ClientPublicInfoDto> {
    if (code === 'localhost' || code === 'pooling') {
      code = 'movesion';
    }
    return this.restService
      .get$<ClientPublicInfoDto>(
        `/clients/public/byCode/${code}`,
        undefined,
        true,
        'gateway'
      )
      .pipe(
        tap(res => this.installationInfo$.next(res))
      );
  }
}
