import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss'],
  standalone: true,
  imports:[
    CommonModule,
    MatDialogModule
  ]
})
export class ModalErrorComponent  {

  testoFiglio: string='';

  constructor(public dialogRef: MatDialogRef<ModalErrorComponent>) { }


  retry(): void {
    this.dialogRef.close();
  }
}
