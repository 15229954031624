import { Injectable } from '@angular/core';
import {  ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { IdamService } from 'app/shared/services/idam.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private router: Router,
              private idamService: IdamService
    // ,public oauth: OauthComponent
  ) {
  }



  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isLogged = this.idamService.isLogged();
    if (!isLogged) {
      void this.router.navigate(['/login'], { replaceUrl: true });
    }
    return isLogged;
  }

  canActivateChild(
    _childRoute: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.idamService.isLogged();
  }
}
