export const environment = {
  production: false,
  policyApiUrl: 'https://api.dev.mobilitycompany.it/gw2/policy',
  poolingApiUrl: 'https://api.dev.mobilitycompany.it/gw3/mpooling',
  apiUrl: 'https://api.dev.mobilitycompany.it/mpooling-old',
  urlSso: 'https://idam.dev.mobilitycompany.it/auth/welcome',
  urlSsoLogout: 'https://idam.dev.mobilitycompany.it/auth/logout',
  urlSsoRecovery:
    'https://idam.dev.mobilitycompany.it/auth/password-recovery',
  urlSsoRegister: 'https://idam.dev.mobilitycompany.it/auth/register',
  googleMapsApiKey: 'AIzaSyD2WYBKObYNxgWwLrjT47GjsW2VcvLIJoo',
  type: 'dev',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
