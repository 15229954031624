import { Component, Input,  } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../config-dialog/confirmdialog.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  standalone: true,
  imports:[
    CommonModule,
    MatDialogModule,
  ]
})
export class MessageComponent {
  @Input() title: string | undefined = '';

  @Input() question: string = '';

  @Input() cancelButton: string | undefined = 'Annulla';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    public dialog: MatDialog
  ) {}



  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
