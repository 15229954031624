import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Client } from '../../models/client';
import { CompanyOutputDto } from '../../models/company.models';
import { ClientPublicInfoDto } from '../../models/installationInfo.model';

export const companyActions = createActionGroup({
  source: 'company',
  events:{
    'Loading Client': emptyProps(),
    'Load Client Completed': props<{client: Client | undefined}>(),
    'Load Client Failed': emptyProps(),
    'Loading Company': emptyProps(),
    'Load Company Completed': props<{company: CompanyOutputDto |undefined}>(),
    'Load Company Failed': emptyProps(),
    'Loading Installation Info': props<{code: string}>(),
    'Load Installation Info Completed': props<{installationInfo: ClientPublicInfoDto |undefined}>(),
    'Load Installation Info Failed': emptyProps(),
  }
});
