import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { policyDocumentsActions } from './policy.document.actions';
import { AcceptedClientDocumentsOutputDto } from 'app/shared/models/document.model';

export const featureName = 'policyDocument';
export type Status = 'Initial' | 'Loading' | 'Ready' | 'Error';

export interface PolicyDocumentsState {
  documents: AcceptedClientDocumentsOutputDto[];
  status: Status;
}

export const initialState: PolicyDocumentsState = {
  documents: [],
  status: 'Initial',
};

export const policyDocumentFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(
      policyDocumentsActions.loadingDocuments,
      (state): PolicyDocumentsState => ({
        ...state,
        status: 'Loading',
      })
    ),
    on(
      policyDocumentsActions.loadingDocumentsCompleted,
      (state, action): PolicyDocumentsState => ({
        ...state,
        documents: action.documents,
        status: 'Ready',
      })
    ),
    on(
      policyDocumentsActions.loadingDocumentsFailed,
      (state): PolicyDocumentsState => ({
        ...state,
        status: 'Error',
      })
    ),
    on(
      policyDocumentsActions.acceptPolicyFailed,
      (state): PolicyDocumentsState => ({
        ...state,
        status: 'Error',
      })
    )
  ),
});

export const { selectDocuments, selectStatus } = policyDocumentFeature;

export const selectIsDocumentsLoading = createSelector(
  selectStatus,
  (status) => status === 'Loading'
);
