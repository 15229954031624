<h1 mat-dialog-title>Seleziona l'orario</h1>

<div id="modal_white" mat-dialog-content>
  <div class="row">
    <div class="modal_body">
      <div class="col-12"><p></p></div>
      <div class="col-12 datepickers">
        <mat-form-field appearance="fill" floatLabel="always">
          <input matInput type="number" maxlength="2" inputmode="numeric" digitOnly="[0-9]*" [(ngModel)]="time.ore">
        </mat-form-field>
        <mat-form-field appearance="fill" floatLabel="always">
          <input matInput type="number" maxlength="2" inputmode="numeric" digitOnly="[0-9]*" [(ngModel)]="time.minuti">
        </mat-form-field>
      </div>
      <div class="d-flex justify-content-center">
        <button id="yes_button" mat-raised-button (click)="modalClose()">Conferma</button>
      </div>
    </div>
  </div>
</div>
