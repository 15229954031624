import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { LanguageSelectionService } from 'app/shared/services/language-selection.service';
import { OreMinuti } from '../timepicker/timepicker.component';

@Component({
  selector: 'app-scegli-data-ora',
  templateUrl: './scegli-data-ora.component.html',
  styleUrls: ['./scegli-data-ora.component.scss']
})
export class ScegliDataOraComponent implements OnInit, OnChanges {

  @Input() data: Date | undefined;
  @Output() changeDate = new EventEmitter<Date>();
  calendar = true;

  timeToCheck: OreMinuti = { ore: 8, minuti: 0 };
  minDate!: Date;

  calendarDate!: Date;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private languageService: LanguageSelectionService
  ) {
    this.dateAdapter.setLocale(this.languageService.getLanguage());
    this.minDate = new Date();
    //new Date(currentDate.setDate(currentDate.getDate() + 1));
    // if (!this.data) {
    //   this.calendarDate = new Date();
    //   this.timeToCheck = { ore: 8, minuti: 0 };
    //
    // }else{
    //   this.calendarDate = new Date(this.data.getFullYear(), this.data.getMonth(), this.data.getDate());
    //   this.timeToCheck = { ore: this.data.getHours(), minuti: this.data.getMinutes() };
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      this.timeToCheck = { ore: changes['data'].currentValue.getHours(), minuti: changes['data'].currentValue.getMinutes() };
      this.calendarDate = new Date(changes['data'].currentValue.getFullYear(), changes['data'].currentValue.getMonth(),
        changes['data'].currentValue.getDate());
    } else {
      this.timeToCheck = { ore: 8, minuti: 0 };
      this.calendarDate = new Date();
    }

  }

  ngOnInit(): void {
    this.calendar = true;
    // this.changeDate.emit(
    //   new Date(this.calendarDate.getFullYear(), this.calendarDate.getMonth(), this.calendarDate.getDate(), this.timeToCheck.ore, this.timeToCheck.minuti)
    // );
  }

  onChangedTime(oreMinuti: OreMinuti): void {
    this.timeToCheck = oreMinuti;
    if (!this.data) {
      this.data = new Date();
    }
    // this.data.setHours(oreMinuti.ore, oreMinuti.minuti, 0);

    this.changeDate.emit(new Date(this.calendarDate.getFullYear(), this.calendarDate.getMonth(), this.calendarDate.getDate(), oreMinuti.ore, oreMinuti.minuti));
  }

  onSelectedDateChanged(date: Date): void {
    // if (!this.data) {
    //   this.data = new Date();
    //   this.data.setHours(8, 0);
    // }
    //
    // this.data = new Date(this.data.setFullYear(date.getFullYear(), date.getMonth(), date.getDate()));
    this.calendarDate = date;
    this.changeDate.emit(new Date(date.getFullYear(), date.getMonth(), date.getDate(), this.timeToCheck.ore, this.timeToCheck.minuti));

  }
}
