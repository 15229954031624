import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AcceptedClientDocumentsOutputDto, ClientDocumentOutputDto } from '../models/document.model';
import { RestService } from './request.service';

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  private documents$: BehaviorSubject<AcceptedClientDocumentsOutputDto[]>;

  constructor(private restService: RestService) {
    this.documents$ = new BehaviorSubject<AcceptedClientDocumentsOutputDto[]>([]);
  }

  getDocuments$(): Observable<AcceptedClientDocumentsOutputDto[]> {
    return this.documents$.asObservable();
  }

  getDocuments(clientId: string): Observable<ClientDocumentOutputDto[]> {
    return this.restService
      .get$<ClientDocumentOutputDto[]>(`documents/clients/${clientId}/actives`, undefined, true, 'policy');
      // .pipe(tap((res) => {
      //   this.documents$.next(res);
      // }));
  }

  getNeededDocuments(moduleCode: string = 'mpooling'): Observable<AcceptedClientDocumentsOutputDto[]>{
    return this.restService.get$<AcceptedClientDocumentsOutputDto[]>(`/documents/me/needed?moduleCode=${moduleCode}`, undefined, true, 'policy')
    .pipe(
      map(
        documents => documents.sort((a,b) =>
          a.clientDocument.moduleCode == null && b.clientDocument.moduleCode != null ? -1 : 1
        )
      ),
      tap(documents => this.documents$.next(documents))
    );
  }

  acceptPolicyDocuments(documentsIds: number[]): Observable<string>{
    return this.restService.post$('/localized-documents/accept', documentsIds, undefined, 'policy')
    .pipe(
      map(() => 'accepted'),
      catchError((err) => {
        throw err;
      }
    ));
  }

}
