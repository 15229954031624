import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { HasPolicybeenAcceptedGuard } from './has-policybeen-accepted.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'auth/login',
    loadComponent: () =>
      import('./pages/login/login.component').then((c) => c.LoginComponent),
  },
  // {
  //   path: 'privacy',
  //   component: PrivacyRegisterComponent
  // },
  {
    path: 'policy',
    loadChildren: () =>
      import('./pages/must-accept-policy/must-accept-policy.module').then(
        (m) => m.MustAcceptPolicyModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () => import('./pages/home.module').then((m) => m.HomeModule),
    pathMatch: 'prefix',
    canActivate: [AuthGuard, HasPolicybeenAcceptedGuard],
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
