<div id="modal_white">
  <!-- <div id="circle_transparent"></div> -->
  <!-- <div id="circle"><mat-icon>priority_high</mat-icon></div> -->
  <div class=" row ">
    <div class="modal_body">
      <h2 *ngIf="title">{{title}}</h2>
      <div><p>{{text}}</p></div>
      <div class="d-flex justify-content-center">
        <button id="no_button" mat-raised-button (click)="close(false)">Annulla</button>
        <button id="yes_button" mat-raised-button (click)="close(true)">Conferma</button>
      </div>
    </div>
  </div>
</div>
