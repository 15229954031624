import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalTimeMinutesComponent } from 'app/shared/modals/modal-time-minutes/modal-time-minutes.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { DecimalPipe } from '@angular/common';


export interface OreMinuti{
  ore: number;
  minuti: number;
}

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatLegacyButtonModule,
    DecimalPipe
  ]
})
export class TimepickerComponent {
  @Input() time: OreMinuti ={ ore: 8, minuti: 0 };


  @Output() changedTime = new EventEmitter<OreMinuti>();


  constructor(public dialog: MatDialog) {
    this.time.ore = 8;
    this.time.minuti = 0;
  }



  addOre(): void {
    if (this.time.ore >= 0 && this.time.ore < 23) {
      this.time.ore = this.time.ore + 1;
    } else {
      this.time.ore = 0;
    }
    this.changedTime.emit({...this.time});
  }

  addMinuti(): void {
    if (this.time.minuti >= 0 && this.time.minuti < 59) {
      this.time.minuti = this.time.minuti + 1;
      this.changedTime.emit({ ...this.time });
    } else {
      this.time.minuti = 0;
      this.addOre();
    }

  }

  removeOre(): void {
    if (this.time.ore >= 1 && this.time.ore <= 24) {
      this.time.ore = this.time.ore - 1;

    } else {
      this.time.ore = 23;
    }
    this.changedTime.emit({ ...this.time });
  }

  removeMinuti(): void {
    if (this.time.minuti >= 1 && this.time.minuti <= 59) {
      this.time.minuti = this.time.minuti - 1;
      this.changedTime.emit({ ...this.time });
    } else {
      this.time.minuti = 59;
      this.removeOre();
    }
  }

  chooseTime(): void {
    const timeRef = this.dialog.open(ModalTimeMinutesComponent, {
      disableClose: true,
      width: '450px',
      data:{
        time: this.time
      }
    });

    timeRef.afterClosed().subscribe((response) => {
      this.time.ore = Number(response.ore);
      this.time.minuti = Number(response.minuti);
      this.changedTime.emit({ ...this.time });
    });
  }
}
