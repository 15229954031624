import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {  Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { IdamService } from './idam.service';
import { Store } from '@ngrx/store';
import { selectInstallationInfo } from '../store/company/company.reducer';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  // private isRefreshing$: BehaviorSubject<boolean> =
  //   new BehaviorSubject<boolean>(false);

  constructor(
    private readonly injector: Injector,
    private idamService: IdamService,
    private router: Router,
    private store: Store
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.indexOf('googleapis.com') > -1 ||
      request.url.endsWith('json')
    ) {
      return next.handle(request);
    }
    const translate = this.injector.get(TranslateService);
    const checkToken = this.idamService.tokenValue;

    request =
      checkToken && checkToken != null
        ? request.clone({
            setHeaders: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            setParams: {
              lang: translate.currentLang,
            },
          })
        : request.clone({
            setParams: {
              lang: translate.currentLang,
            },
          });

    // @ts-ignore
    return next.handle(request).pipe(
      //@ts-ignore
      catchError((err: HttpEvent<any>) => {
        console.log('Im the APP MODULE interceptor working good!');
        if (err instanceof HttpErrorResponse) {
          console.log(
            'APP MODULE interceptor Making LOGOUT, ERROR CODE:',
            err.status
          );
          console.log('REQUEST FAILED:', request.method, request.url);

          if (err.status === 413) {
            return throwError(err);
          }

          if (err.status === 400 && err.error.data?.errorIndex == 1) {
            return;
          }
          if (err.status === 400 && (err?.error?.message || err?.message)) {
            return throwError(err);
          }
          if (
            err.status === 403 ||
            err.status === 401 ||
            err.status === 200 ||
            err.status === 0
          ) {
            if (
              err.status === 401 || err.status === 403 &&
              request.url.indexOf('refreshtoken') > -1
            ) {
              this.logout();
              // this.idamService.logout();
              localStorage.clear();
              return;
            }

            if (this.idamService.refreshTokenValue) {
              const data = {
                token: this.idamService.tokenValue,
                refreshToken: this.idamService.refreshTokenValue,
                urlWeb: '',
              };
              return this.idamService.refreshToken(data).pipe(
                switchMap((response) => {
                  const newToken = response as {
                    token: string;
                    refreshToken: string;
                    updatedAt: Date;
                  };
                  if (newToken) {
                    this.idamService.setToken(
                      newToken.token,
                      newToken.refreshToken,
                      newToken.updatedAt
                    );
                    // localStorage.setItem(CONSTANTS.token, newToken.token);
                    // localStorage.setItem(
                    //   CONSTANTS.refreshToken,
                    //   newToken.refreshToken
                    // );
                  } else {
                    // void this.idamService.logout();
                    void this.logout();
                    localStorage.clear();
                  }
                  return next.handle(
                    this.addTokenHeader(request, newToken.token)
                  );
                }),
                catchError((err) => {
                  localStorage.clear();
                  void this.router.navigateByUrl('/auth/welcome');
                  return throwError(() => err);
                  // return this.idamService.logout();
                })
              );
              //   .subscribe(
              //     (response) => {
              //       const r = response as {
              //         token: string;
              //         refreshToken: string;
              //       };
              //       if (r) {
              //         localStorage.setItem(CONSTANTS.token, r.token);
              //         localStorage.setItem(CONSTANTS.refreshToken, r.refreshToken);
              //       } else {
              //         void this.idamService.logout(); // this.app.logout();
              //         localStorage.clear();
              //       }
              //     },
              //     (_error) => {
              //       void this.idamService.logout(); // this.app.logout();
              //       localStorage.clear();
              //     }
              //   );
              // return;
            } else {
              // this.app.logout();
              void this.logout();
              // void this.idamService.logout();
              localStorage.clear();
              return;
            }
          } else if (err.status === 500) {
            void this.logout();
            // void this.idamService.logout();
          }
          // if(localStorage.getItem('token'))
          // this.idamService.logout()
          // localStorage.clear()
        }
        return throwError(err);
        // return of(err);
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string): any {
    return request.clone({
      headers: request.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`),
    });
  }

  private logout(): void {
    this.store
      .select(selectInstallationInfo)
      .pipe(
        switchMap((installationInfo) => {
          if (installationInfo?.sso && installationInfo.real_sso) {
            return this.idamService.getSsoConfig(installationInfo._id).pipe(
              switchMap((ssoConfig) => {
                if (ssoConfig.allowLogout) {
                  return of(undefined);
                }
                return this.idamService.logout();
              })
            );
          }
          return this.idamService.logout();
        }),
        catchError(() => this.idamService.logout())
      )
      .subscribe(() => {});
  }
}
