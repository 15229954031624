import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CoordinateDto } from 'app/shared/models/travel.models';
import { environment } from 'environments/environment';
import {  Observable, of } from 'rxjs';
import {
  catchError, filter,
  finalize,
  first,
  map,
  switchMap,

  tap
} from 'rxjs/operators';
import { MapGeocoder } from '@angular/google-maps';

import { Store } from '@ngrx/store';
import { selectGoogleMapsWasLoaded } from '../store/maps/maps.reducer';







@Injectable({
  providedIn: 'root'
})
export class GeocodeService {


  constructor(
    private mapGeocoder: MapGeocoder,
    private httpClient: HttpClient,
    private store: Store
  ) {


  }

  loadGoogleMaps(): Observable<any> {


    return this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?libraries=places&key=${environment.googleMapsApiKey}&language=it`, 'callback')
      .pipe(
        map(() => true),

        catchError((error) => {
          console.error(error);
          return of(false);
        }),
        finalize(() => { })
      );


  }




  getAddressFromLatLng(point: CoordinateDto): Observable<string> {
    return this.store.select(selectGoogleMapsWasLoaded).pipe(
      tap((res) => console.log(res)),
      filter((res) => res),
      switchMap(() => {
        const geocoderRequest: google.maps.GeocoderRequest = {
          location: new google.maps.LatLng(point.latitude ?? 43, point.longitude ?? 13),
        };
        return this.mapGeocoder.geocode(geocoderRequest);
      }),
      map((response) => {
        if (response.status === google.maps.GeocoderStatus.OK) {
          return response.results[0].formatted_address;
        }
        return '';
      }),
      first()
    );
  }

  reverseGeocodeWaypoints(wayp: {
    latitude: number;
    longitude: number;
  }): Observable<string> {
    return this.getAddressFromLatLng(wayp);
  }

}
