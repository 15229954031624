import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {  tap } from 'rxjs/operators';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptorService {

  constructor(private loadingService: LoadingService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    // this.loadingService.show();
    return next.handle(req).pipe(
      tap((event) => {
        if(event instanceof HttpResponse){
          this.loadingService.hide();
        }
      }, () => {
        this.loadingService.hide();
      }),
    );
  }
}
