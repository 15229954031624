import { NgModule } from '@angular/core';
import { WktToGoogleMapsPipe } from './wkt.pipe';


@NgModule({
    imports: [],
    exports: [WktToGoogleMapsPipe],
    declarations: [WktToGoogleMapsPipe],
    providers: [],
})
export class WktModule { }
