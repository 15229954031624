import { Injectable } from '@angular/core';
import { TransportMeanInputDto, TransportMeanOutputDto } from 'app/shared/models/transportmeans.models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonDialogService } from './common-dialog.service';
import { NotificationService } from './notification.service';
import { RestService } from './request.service';


@Injectable({
  providedIn: 'root'
})
export class MezziService {
  mezzo$: BehaviorSubject<TransportMeanInputDto | undefined>;

  constructor(
    private restService: RestService,
    private dialogService: CommonDialogService,
    private notificationService: NotificationService
  ) {
    this.mezzo$ = new BehaviorSubject<TransportMeanInputDto | undefined>(
      undefined
    );
  }


  addMezzo(mezzo: TransportMeanInputDto): Observable<TransportMeanOutputDto[]> {
    return this.restService
      .post$<TransportMeanOutputDto[]>(
        '/employee/me/transport-means/add',
        mezzo
      )
      .pipe(
        // tap(res => console.log(res)),
        catchError((err) => {
          this.dialogService.showErrorDialog('Si è verificato un errore');
          throw err;
        })
      );
  }


  getMezzi(): Observable<TransportMeanOutputDto[]> {
    return this.restService.get$<TransportMeanOutputDto[]>(
      '/employee/me/transport-means'
    );
  }

  updateMezzo(
    mezzo: TransportMeanInputDto,
    id: number | undefined
  ): Observable<any> {
    return this.restService
      .put$<TransportMeanOutputDto>(`/employee/me/transport-means/${id}`, mezzo)
      .pipe(
        catchError((err) => {
          this.dialogService.showErrorDialog('Si è verificato un errore');
          throw err;
        })
      );
  }

  deleteMezzo(id: number): Observable<boolean> {
    return this.restService
      .deleteEmptyResult$(`/employee/me/transport-means/${id}`)
      .pipe(
        map(() => true),
        catchError((err) => {
          if (err.error.code === 'generic_error') {
            this.notificationService.showError('Non possono essere eliminati mezzi associati a corse programmate');
            return of(false);
          }
          // this.dialogService.showErrorDialog('Si è verificato un errore');
          throw err;
        })
      );
  }
}
