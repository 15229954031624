import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Store } from '@ngrx/store';

import { IdamService } from 'app/shared/services/idam.service';
import { selectInstallationInfo } from 'app/shared/store/company/company.reducer';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-modal-exit',
  templateUrl: './modal-exit.component.html',
  styleUrls: ['./modal-exit.component.scss'],
  standalone: true,
  imports:[
    CommonModule
  ]
})
export class ModalExitComponent {
  constructor(
    private dialogRef: MatDialogRef<ModalExitComponent>,

    private idamService: IdamService,
    private store: Store
  ) { }



  logout(): void {
    this.store
      .select(selectInstallationInfo)
      .pipe(
        switchMap((installationInfo) => {
          if (installationInfo?.sso && installationInfo.real_sso) {
            return this.idamService.getSsoConfig(installationInfo._id).pipe(
              switchMap((ssoConfig) => {
                if (ssoConfig.allowLogout) {
                  localStorage.clear()
                  window.location.href = environment.urlSsoLogout;
                  return of(undefined);
                }
                return this.idamService.logout();
              })
            );
          }
          return this.idamService.logout();
        }),
        catchError(() => this.idamService.logout())
      )
      .subscribe((res) => {
        if (res) {
          this.dialogRef.close();

          // this.idamService.logout().subscribe(() => {
          //   void this.router.navigateByUrl('/login');
          //   this.dialogRef.close();
          // });
        }
      });
  }

  modalClose(): void {
    this.dialogRef.close();
  }
}
