import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { CompanyOutputDto } from '../../models/company.models';
import { Client } from '../../models/client';
import { companyActions } from './company.actions';
import { ClientPublicInfoDto } from '../../models/installationInfo.model';

export const companyFeatureName = 'company';

export interface CompanyState{

  isLoading: boolean;
  client: Client | undefined;
  company: CompanyOutputDto | undefined;

  installationInfo: ClientPublicInfoDto | undefined;
}

const initialState: CompanyState = {

  isLoading: false,
  client: undefined,
  company: undefined,
  installationInfo: undefined
};

export const companyFeature = createFeature({
  name: companyFeatureName,
  reducer: createReducer(
    initialState,
    on(companyActions.loadingClient, (state) => ({...state, isLoading: true})),
    on(companyActions.loadClientCompleted, (state, action) => ({...state, isLoading: false, client: action.client})),
    on(companyActions.loadClientFailed, (state) => ({...state, isLoading: false, client: undefined})),

    on(companyActions.loadingCompany, (state) => ({...state, isLoading: true})),
    on(companyActions.loadCompanyCompleted, (state, action) => ({...state, isLoading: false, company: action.company})),

    on(companyActions.loadingInstallationInfo, (state)=>({...state, isLoading: true})),
    on(companyActions.loadInstallationInfoCompleted, (state, action)=>({...state, isLoading: false, installationInfo:action.installationInfo})),
    on(companyActions.loadInstallationInfoFailed, (state)=>({...state, isLoading: false, installationInfo: undefined})),

  )
});

export const {
  selectIsLoading,
  selectClient,
  selectCompany,
  selectInstallationInfo
} = companyFeature;



export const selectRagioneSociale = createSelector( selectClient, (client) => client?.name ?? 'N/A' );

export const selectIsPriceSet = createSelector(selectCompany, (company)=> company?.canEmployeeSetPriceForRide);
