import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GeocodeService } from 'app/shared/services/geocode.service';
import { switchMap } from 'rxjs/operators';
import { mapsActions } from './maps.actions';



@Injectable()
export class MapsEffects {

  loadingRequests$ = createEffect(() => this.actions$.pipe(
    ofType(mapsActions.loading),
    switchMap(() => this.geocodeService.loadGoogleMaps()),
    switchMap(() => [mapsActions.loaded()])
  ));

  constructor(private actions$: Actions,
              private geocodeService: GeocodeService,

  ) {
  }
}
