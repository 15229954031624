import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { OreMinuti } from 'app/shared/components/timepicker/timepicker.component';
import { CommonDialogService } from 'app/shared/services/common-dialog.service';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-modal-time-minutes',
  templateUrl: './modal-time-minutes.component.html',
  styleUrls: ['./modal-time-minutes.component.scss'],
  standalone: true,
  imports:[
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    MatDialogModule,
  ]
})
export class ModalTimeMinutesComponent  {
  time: OreMinuti = { ore: 8, minuti: 0 };

  constructor(
    public dialogRef: MatDialogRef<ModalTimeMinutesComponent>,
    public dialog: MatDialog,
    private dialogService: CommonDialogService,
    @Inject(MAT_DIALOG_DATA) data: {time: OreMinuti}
  ) {
    this.time = data.time;
  }

  modalClose(): void {

    if (this.time.ore < 0 || this.time.ore > 23 ) {
      this.dialogService.showErrorDialog('L\'ora inserita non è valida');

    } else {
      if (
        this.time.minuti < 0 ||
        this.time.minuti > 59
      ) {
        this.dialogService.showErrorDialog('I minuti inseriti non sono validi');
      } else {
        this.dialogRef.close(this.time);
      }
    }
  }


}
