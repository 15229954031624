<h2 mat-dialog-title *ngIf="title">{{title}}</h2>
<div mat-dialog-content>
  <div class=" row text-center">
    <div class="modal_body">
      
      <div><p>{{question}}</p></div>
      <div>
        <button *ngIf='cancelButton' type="button" mcButton variant="closedialog" (click)="onConfirm()" mat-dialog-close>{{cancelButton}}</button>
      </div>
    </div>
  </div>
</div>

