import { Injectable } from '@angular/core';
import { LocationDto, TravelInformationDto } from '../models/travel.models';
import { TransportMeanOutputDto } from '../models/transportmeans.models';

const LAST_STARTING_DATE = 'lastStartingDate';
const LAST_PERCORSO_OFFER = 'lastPercorso';
const LAST_PERCORSO_SEARCH = 'lastPercorsoSearch';
const LAST_PRICE = 'lastPrice';
const LAST_TRANSPORT_MEAN = 'lastTransportMean';

@Injectable({providedIn: 'root'})
export class MemoryStorageService {

  constructor() {
  }

  get lastPercorsoSearch(): {origin: LocationDto, destination:LocationDto} | undefined {
    const lastPercorsoSearch = localStorage.getItem(LAST_PERCORSO_SEARCH);
    if (lastPercorsoSearch) {
      return JSON.parse(lastPercorsoSearch);
    } else {
      return undefined;
    }
  }

  storeLastPercorsoSearch(lastPercorsoSearch: {origin: LocationDto, destination:LocationDto} | undefined):void {
    if (lastPercorsoSearch) {
      localStorage.setItem(LAST_PERCORSO_SEARCH, JSON.stringify(lastPercorsoSearch));
    } else {
      localStorage.removeItem(LAST_PERCORSO_SEARCH);
    }
  }

  get lastTransportMean(): TransportMeanOutputDto | undefined {
    const lastTransportMean = localStorage.getItem(LAST_TRANSPORT_MEAN);
    if (lastTransportMean) {
      return JSON.parse(lastTransportMean) as TransportMeanOutputDto;
    } else{
      return undefined;
    }
  }

  storeLastTransportMean(transportMean: TransportMeanOutputDto | undefined): void {
    if (transportMean) {
      localStorage.setItem(LAST_TRANSPORT_MEAN, JSON.stringify(transportMean));
    } else {
      localStorage.removeItem(LAST_TRANSPORT_MEAN);
    }
  }

  get lastPrice(): number {
    const lastPrice = localStorage.getItem(LAST_PRICE);
    if (lastPrice) {
      return parseFloat(lastPrice);
    } else {
      return 0;
    }
  }

  storePrice(price: number | undefined): void {
    if (price) {
      localStorage.setItem(LAST_PRICE, JSON.stringify(price));
    }else {
      localStorage.removeItem(LAST_PRICE);
    }
  }

  get lastPercorsoOfferta(): Omit<TravelInformationDto, 'duration' | 'length' | 'geom' > | undefined {
    const lastPercorsoJson = localStorage.getItem(LAST_PERCORSO_OFFER);
    if (lastPercorsoJson) {
      return JSON.parse(lastPercorsoJson) as Omit<TravelInformationDto, 'duration' | 'length' | 'geom'>;
    }else {
      return undefined;
    }
  }

  storeLastPercorsoOfferta(lastPercorso: Omit<TravelInformationDto, 'duration' | 'length' | 'geom'> | undefined): void {
    if (lastPercorso) {
      localStorage.setItem(LAST_PERCORSO_OFFER, JSON.stringify(lastPercorso));
    }else {
      localStorage.removeItem(LAST_PERCORSO_OFFER);
    }
  }

  get lastStartingDate(): Date | undefined {
    // @ts-ignore
    return localStorage.getItem(LAST_STARTING_DATE) ? new Date(localStorage.getItem(LAST_STARTING_DATE)) : undefined;
  }

  storeStartingDate(date: Date | undefined): void {
    if (date) {
      localStorage.setItem(LAST_STARTING_DATE, date.toISOString());
    } else {
      localStorage.removeItem(LAST_STARTING_DATE);
    }
  }

  clearAllStorage(): void {
    this.storeLastPercorsoSearch(undefined);
    this.storePrice(undefined);
    this.storeStartingDate(undefined);
    this.storeLastTransportMean(undefined);
    this.storeLastPercorsoOfferta(undefined);
  }


}
