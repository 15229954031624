import { Component, DestroyRef, inject } from '@angular/core';
import { LoadSpinnerService } from 'app/shared/services/loadspinner.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-load-spinner',
  template: ` <div *ngIf="showing$ | async">
    <svg
      width="106.68mm"
      height="53.34mm"
      viewBox="0 0 302.4 151.21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>
          .cls-1 {
            fill: #30373a;
          }

          .cls-2 {
            fill: #b01e54;
          }
        </style>
      </defs>
      <title>mobility-logo_1</title>
      <g data-name="Layer 2" id="m">
        <path
          class="cls-1"
          d="M160.64,0a65.29,65.29,0,0,0-47.25,19.79A64.74,64.74,0,0,0,66.15,0,66,66,0,0,0,0,66.15v66.14A19,19,0,0,0,18.9,151.2a19,19,0,0,0,18.9-18.91V66.15a28.35,28.35,0,1,1,56.69,0v66.14a18.9,18.9,0,1,0,37.8,0V66.15a28.36,28.36,0,0,1,56.71,0v66.14a19,19,0,0,0,18.9,18.91,18.69,18.69,0,0,0,11.24-3.78,56.79,56.79,0,0,1,7.66-76.17v-5.1A66,66,0,0,0,160.64,0Z"
        />
      </g>
      <g data-name="Layer 1" id="ball">
        <path
          class="cls-2"
          d="M253.37,148c5,.33,10.85-2.94,16.31-8.66a.46.46,0,0,0-.16-.74l-10.24-4.29a.76.76,0,0,0-1,.4L253,147.34A.46.46,0,0,0,253.37,148Z"
        />
        <path
          class="cls-2"
          d="M247.85,145.67c-3.73-3.3-5.53-9.79-5.28-17.69a.46.46,0,0,1,.64-.41l10.24,4.28a.76.76,0,0,1,.41,1l-5.29,12.65A.47.47,0,0,1,247.85,145.67Z"
        />
        <path
          class="cls-2"
          d="M241.59,106.37l-12-5a.77.77,0,0,0-1,.46,37.6,37.6,0,0,0-1.79,13.2.77.77,0,0,0,.47.67L238,120.17a.45.45,0,0,0,.62-.34,70.45,70.45,0,0,1,3.42-12.5A.75.75,0,0,0,241.59,106.37Z"
        />
        <path
          class="cls-2"
          d="M261.13,129.86l11.69,4.89a.75.75,0,0,0,.9-.24,64.84,64.84,0,0,0,6.61-11.06.77.77,0,0,0-.4-1l-13.35-5.59a.76.76,0,0,0-1,.41l-4.87,11.63A.76.76,0,0,0,261.13,129.86Z"
        />
        <path
          class="cls-2"
          d="M246.41,109.25a65.26,65.26,0,0,0-3.27,12.46.77.77,0,0,0,.47.82l11.7,4.89a.76.76,0,0,0,1-.4l4.86-11.64a.76.76,0,0,0-.4-1l-13.34-5.58A.77.77,0,0,0,246.41,109.25Z"
        />
        <path
          class="cls-2"
          d="M268.05,97l-11.7-4.9a.76.76,0,0,0-.9.25,65,65,0,0,0-6.59,11,.77.77,0,0,0,.4,1L262.61,110a.75.75,0,0,0,1-.41L268.45,98A.76.76,0,0,0,268.05,97Z"
        />
        <path
          class="cls-2"
          d="M265.69,75.62a37.93,37.93,0,0,0-20.95,5.62.46.46,0,0,0,.07.81l9,3.76a.77.77,0,0,0,.86-.2,39.54,39.54,0,0,1,11.24-9.13A.46.46,0,0,0,265.69,75.62Z"
        />
        <path
          class="cls-2"
          d="M238.5,140.76a37.89,37.89,0,0,1-10.71-18.86.46.46,0,0,1,.63-.52l9,3.76a.74.74,0,0,1,.46.74,39.64,39.64,0,0,0,1.39,14.42A.45.45,0,0,1,238.5,140.76Z"
        />
        <path
          class="cls-2"
          d="M290.69,86.08a37.88,37.88,0,0,1,10.7,18.86.46.46,0,0,1-.62.52l-9-3.76a.74.74,0,0,1-.46-.74,39.65,39.65,0,0,0-1.38-14.42A.45.45,0,0,1,290.69,86.08Z"
        />
        <path
          class="cls-2"
          d="M287.6,120.42l12,5a.77.77,0,0,0,1-.47,37.76,37.76,0,0,0,1.79-13.15.79.79,0,0,0-.48-.67l-10.68-4.47a.45.45,0,0,0-.62.34,70.71,70.71,0,0,1-3.4,12.45A.75.75,0,0,0,287.6,120.42Z"
        />
        <path
          class="cls-2"
          d="M250.67,89.71,240,85.25a.78.78,0,0,0-.81.14,37.49,37.49,0,0,0-8.11,10.5.77.77,0,0,0,.39,1l11.94,5a.76.76,0,0,0,1-.38,70.9,70.9,0,0,1,6.46-11.18A.44.44,0,0,0,250.67,89.71Z"
        />
        <path
          class="cls-2"
          d="M275.82,78.84c-5-.34-10.87,2.94-16.33,8.68a.46.46,0,0,0,.15.74l10.26,4.29a.75.75,0,0,0,1-.41l5.3-12.67A.45.45,0,0,0,275.82,78.84Z"
        />
        <path
          class="cls-2"
          d="M281.35,81.15c3.73,3.3,5.53,9.81,5.28,17.72a.46.46,0,0,1-.64.41L275.73,95a.76.76,0,0,1-.4-1l5.3-12.67A.47.47,0,0,1,281.35,81.15Z"
        />
        <path
          class="cls-2"
          d="M263.5,151.19a37.78,37.78,0,0,0,20.91-5.6.46.46,0,0,0-.06-.81l-9-3.76a.76.76,0,0,0-.85.2,39.77,39.77,0,0,1-11.23,9.12A.45.45,0,0,0,263.5,151.19Z"
        />
        <path
          class="cls-2"
          d="M284.8,125.24a70.57,70.57,0,0,1-6.5,11.21.46.46,0,0,0,.2.68l10.64,4.45a.75.75,0,0,0,.81-.13,37.73,37.73,0,0,0,8.14-10.54.78.78,0,0,0-.39-1.06l-11.93-5A.74.74,0,0,0,284.8,125.24Z"
        />
        <path
          class="cls-2"
          d="M272.88,99.82,268,111.41a.75.75,0,0,0,.41,1L281.77,118a.76.76,0,0,0,1-.44A65.3,65.3,0,0,0,286,105.13a.77.77,0,0,0-.47-.82l-11.7-4.89A.75.75,0,0,0,272.88,99.82Z"
        />
      </g>
    </svg>
  </div>`,
})
export class LoadSpinnerComponent  {
  showing$!: Observable<boolean>;


  private destroyRef = inject(DestroyRef);

  constructor(loadSpinnerService: LoadSpinnerService) {
    this.showing$ = loadSpinnerService.showSpinner$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef)
    );
  }

}
