import {Injectable} from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ClientService } from '../../services/client.service';
import { CompanyService } from '../../services/company.service';
import { mergeMap, switchMap } from 'rxjs/operators';
import { companyActions } from './company.actions';





@Injectable()
export class CompanyEffects {


  loadingClient$ = createEffect(
    () => this.actions$.pipe(
      ofType(companyActions.loadingClient),
      mergeMap(() => this.clientService.getClientInfo()),
      switchMap((client) => {
        if (client) {
          return [companyActions.loadClientCompleted({ client })];
        } else {
          return [companyActions.loadClientFailed()];
        }
      })
    ), { dispatch: true });

  loadingCompany$ = createEffect(
    () => this.actions$.pipe(
      ofType(companyActions.loadingCompany),
      mergeMap(() => this.companyService.loadCompany()),
      switchMap((company) => {
        if (company) {
          return [companyActions.loadCompanyCompleted({ company })];
        } else {
          return [companyActions.loadCompanyFailed()];
        }
      })
    ), { dispatch: true });

  loadingInstallationInfo$ = createEffect(
    () => this.actions$.pipe(
      ofType(companyActions.loadingInstallationInfo),
      mergeMap((action) => this.clientService.loadInstallationInfo(action.code)),
      switchMap((installationInfo) => {
        if (installationInfo) {
          return [companyActions.loadInstallationInfoCompleted({ installationInfo })];
        } else {
          return [companyActions.loadInstallationInfoFailed()];
        }
      })
    ), { dispatch: true });


  constructor(private actions$: Actions,

              private clientService: ClientService,
              private companyService: CompanyService,

              ) {
  }




}
