import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EmployeeDto, EmployeeMeInputDto } from '../../models/employee.models';
import { PoolingPreferenceDto } from '../../models/mPooling.models';
import { TravelInformationDto } from '../../models/travel.models';
import { TransportMeanInputDto, TransportMeanOutputDto } from '../../models/transportmeans.models';

export const generalActions = createActionGroup({
  source: 'general',
  events:{
    'Load Employee': emptyProps(),
    'Loaded Employee' : props<{employee: EmployeeDto}>(),
    'Loading Employee Failed': emptyProps(),
    'Updating Employee Pooling Preferences': props<{ preferences: PoolingPreferenceDto }>(),
    'Updated Employee Pooling Preferences': props<{ preferences: PoolingPreferenceDto }>(),
    'Updating Employee Pooling Preferences Failed':emptyProps(),

    'Adding Mezzo': props<{ mezzo: TransportMeanInputDto }>(),
    'Adding Mezzo failed': emptyProps(),
    'Updated Mezzi': props<{ mezzi: TransportMeanOutputDto[] }>(),


    'Updated TravelInformation': props<{ travelInformation: TravelInformationDto }>(),




    'Update Profile': props<{ employee: EmployeeMeInputDto }>(),
    'Update Profile Completed': props<{update: EmployeeMeInputDto}>(),
    'Update Profile Failed': emptyProps(),
  }
});

