/**
 * https://github.com/angular-material-extensions/google-maps-autocomplete
 */
import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Inject,
  Input,
  NgZone, OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GeocodeService } from 'app/shared/services/geocode.service';
import { GermanAddress } from '../interfaces/germand.address.interface';
import { Location } from '../interfaces/location.interface';

import PlaceResult = google.maps.places.PlaceResult;
import AutocompleteOptions = google.maps.places.AutocompleteOptions;
import { selectGoogleMapsWasLoaded } from '../../store/maps/maps.reducer';
import { Store } from '@ngrx/store';


@Directive({
  selector: '[matGoogleMapsAutocomplete]',
  exportAs: 'matGoogleMapsAutocomplete',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatGoogleMapsAutocompleteDirective),
      multi: true
    }
  ]
})
export class MatGoogleMapsAutocompleteDirective
  implements OnInit, ControlValueAccessor, OnDestroy
{
  @Input()
  //@ts-ignore
  address: PlaceResult | string;

  @Input()
  //@ts-ignore
  country: string | string[] = 'it';

  @Input()
  placeIdOnly?: boolean;

  @Input()
  strictBounds?: boolean;

  @Input()
  types?: string[];

  @Input()
  type: string = '';

  @Input()
  autoCompleteOptions: AutocompleteOptions = {};

  @Output()
  onChange: EventEmitter<PlaceResult | string | null> = new EventEmitter<
    PlaceResult | string | null
  >();

  @Output()
  onAutocompleteSelected: EventEmitter<PlaceResult> = new EventEmitter<PlaceResult>();

  @Output()
  onGermanAddressMapped: EventEmitter<GermanAddress> = new EventEmitter<GermanAddress>();

  @Output()
  onLocationSelected: EventEmitter<Location> = new EventEmitter<Location>();
  //@ts-ignore
  disabled: boolean;
  //@ts-ignore
  _value: string;


  private _autoComplete : google.maps.places.Autocomplete | undefined;
  private _autoCompleteLsr: google.maps.MapsEventListener | undefined;
  get value(): string {
    return this._value;
  }

  @Input()
  set value(value: string) {
    this._value = value;
    this.propagateChange(this.value);
    this.cf.markForCheck();
  }


  // private onNewPlaceResult: EventEmitter<any> = new EventEmitter();

  propagateChange = (_: any) => {
  };

  constructor(@Inject(PLATFORM_ID) public platformId: string,
              // @Optional() @Self() public ngControl: NgControl,
              public elemRef: ElementRef,
              public mapsAPILoader: GeocodeService,
              private cf: ChangeDetectorRef,
              private ngZone: NgZone,
              private store : Store) {
  }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      const options: AutocompleteOptions = {
        // types: ['address'],
        // componentRestrictions: {country: this.country},
        placeIdOnly: this.placeIdOnly,
        strictBounds: this.strictBounds,
        // types: this.types,
        types: [this.type]
      };

      // tslint:disable-next-line:no-unused-expression
      this.country ? options.componentRestrictions = {country: this.country} : null;
      // tslint:disable-next-line:no-unused-expression
      this.country ? options.types = this.types : null;

      this.autoCompleteOptions = Object.assign(this.autoCompleteOptions, options);
      this.initGoogleMapsAutocomplete();
    }
  }

  @HostListener('change')
  onChangeInputValue(): void {
    const value = (this.elemRef.nativeElement as HTMLInputElement)?.value;
    this.value = value;
  }

  private initGoogleMapsAutocomplete(): void {
    this.store.select(selectGoogleMapsWasLoaded).subscribe((isLoaded: boolean) => {
      if (isLoaded) {
        this._autoComplete = new google.maps.places.Autocomplete(this.elemRef.nativeElement, this.autoCompleteOptions);
        this._autoCompleteLsr =  this._autoComplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            if (this._autoComplete) {
              // get the place result
              const place: PlaceResult = this._autoComplete.getPlace();

              const germanAddress: GermanAddress = {
                gmID: place.place_id,
                icon: place.icon,
                url: place.url,
                placeID: place.place_id,
                displayAddress: place.formatted_address,
                name: place.name,
                vicinity: place.vicinity,
                locality: {},
                state: {},
                country: {},
                geoLocation: {latitude: -1, longitude: -1},
              };

              if (place.geometry && place.geometry.location) {
                //@ts-ignore
                germanAddress.geoLocation.latitude =
                  place.geometry.location.lat();
                //@ts-ignore
                germanAddress.geoLocation.longitude =
                  place.geometry.location.lng();
              }
              //@ts-ignore
              place.address_components.forEach((value) => {
                if (value.types.indexOf('street_number') > -1) {
                  germanAddress.streetNumber = value.short_name;
                }
                if (value.types.indexOf('route') > -1) {
                  germanAddress.streetName = value.long_name;
                }
                if (value.types.indexOf('postal_code') > -1) {
                  germanAddress.postalCode = Number(value.short_name);
                }
                if (value.types.indexOf('sublocality') > -1) {
                  germanAddress.sublocality = value.long_name;
                }
                if (value.types.indexOf('locality') > -1) {
                  //@ts-ignore
                  germanAddress.locality.long = value.long_name;
                  //@ts-ignore
                  germanAddress.locality.short = value.short_name;
                }
                if (value.types.indexOf('administrative_area_level_1') > -1) {
                  //@ts-ignore
                  germanAddress.state.long = value.long_name;
                  //@ts-ignore
                  germanAddress.state.short = value.short_name;
                }
                if (value.types.indexOf('country') > -1) {
                  //@ts-ignore
                  germanAddress.country.long = value.long_name;
                  //@ts-ignore
                  germanAddress.country.short = value.short_name;
                }
                if (value.types.indexOf('administrative_area_level_3') > -1) {
                  //@ts-ignore
                  germanAddress.locality.short = value.short_name;
                }
              });

              this.onGermanAddressMapped.emit(germanAddress);
              //@ts-ignore
              this.value = place.formatted_address;
              //@ts-ignore
              this.address = place.formatted_address;
              this.onAutocompleteSelected.emit(place);
              this.onLocationSelected.emit({
                //@ts-ignore
                latitude: place.geometry.location.lat(), //@ts-ignore
                longitude: place.geometry.location.lng(),
              });
            }
          });
        });
      }
    });
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(_fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  ngOnDestroy(): void {
    if (this._autoCompleteLsr){
      google.maps.event.removeListener(this._autoCompleteLsr);
      this._autoCompleteLsr = undefined;
    }
    if (this._autoComplete) {

      google.maps.event.clearInstanceListeners(this._autoComplete);
      this._autoComplete = undefined;
    }
    document.querySelectorAll('.pac-container').forEach((element) => {
      element.parentNode?.removeChild(element);
    });

  }

}
