import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class LoadSpinnerService {

  private _showSpinner = new BehaviorSubject<boolean>(false);
  constructor() { }

  get showSpinner$(): Observable<boolean> {
    return this._showSpinner.asObservable();
  }

  showSpinner(): void {
   this._showSpinner.next(true);
  }

  hideSpinner(): void {
    this._showSpinner.next(false);
  }


}
