import { EmployeeDto } from '../../models/employee.models';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { generalActions } from './general.actions';

export const generalFeatureName = 'general';

export interface GeneralState{
  employee: EmployeeDto | undefined;
  isLoading: boolean;
}

export const initialState: GeneralState = {

  employee: undefined,
  isLoading: false,
  // profileDipendenteCompleted: false,
  // profileCompletedToOfferPass: false,
  // client: undefined,
  // company: undefined,
  // installationInfo: undefined
};

export const generalFeature = createFeature({
  name: generalFeatureName,
  reducer: createReducer(
    initialState,
    on(generalActions.loadEmployee, (state): GeneralState =>({...state, isLoading: true, employee: undefined})),
    on(generalActions.loadedEmployee, (state, props)=> ({...state, isLoading: false, employee: props.employee})),
    on(generalActions.loadingEmployeeFailed, (state)=>({...state, isLoading: false, employee: undefined})),
    on(generalActions.updateProfile, (state) => ({...state, isLoading: true})),
    on(generalActions.updateProfileCompleted, (state, action) => {
      if(state.employee){
        const updatedEmployee = {...state.employee};
        Object.assign(updatedEmployee, action.update);
        return {...state, isLoading: false, employee: updatedEmployee};
      }
      return {...state, isLoading: false};
    }),
    on(generalActions.updateProfileFailed, (state) => ({...state, isLoading: false})),
    on(generalActions.updatedMezzi, (state, action) => {
      if (state.employee) {
        const employee = {...state.employee};
        employee.transportMeans = action.mezzi;
        return {...state, employee, isLoading: false};
      }else{
        return {...state, isLoading: false};
      }
    }),

    on(generalActions.updatedTravelInformation, (state, action) => {
      if (state.employee) {
        const employee = {...state.employee};
        employee.travelInformation = action.travelInformation;
        return {...state, employee, isLoading: false};
      }else{
        return {...state, isLoading: false};
      }
    }),
    on(generalActions.updatingEmployeePoolingPreferences, (state) => ({...state, isLoading: true})),
    on(generalActions.updatedEmployeePoolingPreferences, (state, action) => {
      if (state.employee) {
        const employee: EmployeeDto = {...state.employee};
        employee.poolingPreference = {...action.preferences};

        return {...state, employee, isLoading: false};
      }
      return {...state, isLoading: false};
    }),
    on(generalActions.updatingEmployeePoolingPreferencesFailed, (state) => ({...state, isLoading: false})),
  ),
});


export const  {
  selectEmployee,
  selectIsLoading
} = generalFeature;


// export const isProfileCompletedToOfferPass = createSelector( selectGeneralState, (state: GeneralState) => state.profileCompletedToOfferPass );

export const selectIsProfileCompletedToOffer = createSelector(selectEmployee, (employee)=>{
  if (employee)  {
    return isEmployeeProfileCompletedToOfferPass(employee);
  }
  return false;
});

export const selectIsProfileCompleted = createSelector(selectEmployee, (employee)=>{
  if (employee)  {
    return isEmployeeProfileCompleted(employee);
  }
  return false;
});

export const selectTransportMeans = createSelector( selectEmployee, (employee) => employee?.transportMeans ?? [] );

function isEmployeeProfileCompletedToOfferPass(dipendente: EmployeeDto): boolean{
  return (dipendente &&
    dipendente.phone != '' &&
    dipendente.poolingPreference != undefined &&
    dipendente.poolingPreference?.transportTypes != undefined &&
    dipendente.poolingPreference?.transportTypes?.length > 0 &&
    dipendente.travelInformation != undefined &&
    dipendente.travelInformation != null &&
    dipendente.transportMeans != undefined &&
    dipendente.transportMeans.length > 0 &&
    //dipendente.authorizePrivacyWhen != undefined &&
    dipendente.withAssuranceAndDrivingLicense);
}

function isEmployeeProfileCompleted(dipendente: EmployeeDto): boolean {
  return (
    dipendente &&
    dipendente.phone != '' &&
    dipendente.poolingPreference != undefined &&
    dipendente.poolingPreference?.transportTypes !== undefined &&
    dipendente.poolingPreference?.transportTypes?.length > 0 &&
    dipendente.travelInformation != undefined &&
    dipendente.travelInformation != null
    // &&  dipendente.authorizePrivacyWhen != undefined
  );
}
