import { Component, DestroyRef, inject,  OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadSpinnerService } from 'app/shared/services/loadspinner.service';
import { ClientService } from './shared/services/client.service';
import { IdamService } from './shared/services/idam.service';
import { Store } from '@ngrx/store';
import { generalActions } from './shared/store/general/general.actions';
import { mapsActions } from './shared/store/maps/maps.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  template: `

    <app-load-spinner></app-load-spinner>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [
    `
      .container-fluid {
        background: #f2f2f2;
      }
    `,
  ],
})
export class AppComponent implements OnInit {


  private destroyRef = inject(DestroyRef);

  constructor(
    private loaderSpinner: LoadSpinnerService,
    private clientService: ClientService,
    private idamService: IdamService,
    private store: Store,
    translate: TranslateService
  ) {
    translate.setDefaultLang('it');
    if (this.idamService.isLogged()){
      this.store.dispatch(generalActions.loadEmployee());
      this.store.dispatch(mapsActions.loading());
    }
  }

  ngOnInit(): void {
    this.loaderSpinner.hideSpinner();
    const hostNameSplitted = window.location.hostname.split('.');
    const code = hostNameSplitted[0];
    this.clientService
      .loadInstallationInfo(code)
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        //
      });
  }
}
