import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { mapsActions } from './maps.actions';

export const mapsFeatureName = 'mapsFeature';
export interface MapsState{
  isLoaded: boolean;
  isLoading: boolean;
}

export const initialState: MapsState = {
  isLoading: false,
  isLoaded: false,
};


export const mapsFeature=  createFeature({
  name: mapsFeatureName,
  reducer:createReducer(
  initialState,
  on(mapsActions.loading, (state)=>({...state, isLoading: true})),
  on(mapsActions.loading, (state)=>({...state, isLoading: false, isLoaded: true})),
)
});

export const {
  selectIsLoading,
  selectIsLoaded
} = mapsFeature;


export const selectGoogleMapsIsLoading = createSelector(selectIsLoading, (isLoading) => isLoading);
export const selectGoogleMapsWasLoaded = createSelector(selectIsLoaded, (isLoaded: boolean) => isLoaded);
