import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import {
  Status,
  selectDocuments,
  selectStatus,
} from './shared/store/policy/policy.document.reducer';
import { Store } from '@ngrx/store';
import { policyDocumentsActions } from './shared/store/policy/policy.document.actions';

@Injectable({
  providedIn: 'root',
})
export class HasPolicybeenAcceptedGuard {
  constructor(private router: Router, private store: Store) {}

  getStatus(): Observable<Status> {
    return this.store.select(selectStatus).pipe(
      tap((status) => {
        if (status === 'Initial') {
          this.store.dispatch(policyDocumentsActions.loadingDocuments());
        }
      }),
      filter((status) => status === 'Ready')
    );
  }

  canActivate(
    // eslint-disable-next-line no-unused-vars
    _route: ActivatedRouteSnapshot,
    // eslint-disable-next-line no-unused-vars
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.getStatus().pipe(
      switchMap(() => this.store.select(selectDocuments)),
      map((documents) => {
        if (!documents.every((d) => d.acceptedAt)) {
          void this.router.navigateByUrl('policy/mustaccept');
          return false;
        }
        return true;
      })
    );
  }
}
