import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from 'app/constants';
import { environment } from 'environments/environment';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { MemoryStorageService } from './memoryStorage';
import { RestService } from './request.service';
import { OAuth2ConfigOpenOutputDto } from '../models/sso.config.model';


@Injectable({
  providedIn: 'root'
})
export class IdamService {

  constructor(private rest: RestService, private router: Router, private memoryStorage: MemoryStorageService) { }

  get refreshTokenValue(): string {
    return localStorage.getItem(CONSTANTS.refreshToken) ?? '';
  }

  get tokenValue(): string {
    return localStorage.getItem(CONSTANTS.token) ?? '';
  }

  get updateAt(): string{
    return localStorage.getItem(CONSTANTS.updatedAt) ?? '';
  }

  refreshToken(refreshToken: { token: string; refreshToken: string| undefined }): Observable<{ token: string; refreshToken: string; updatedAt: Date }> {

    const payload = {
      token: refreshToken.token,
      refreshToken: refreshToken.refreshToken,
      urlWeb: ''
    };

    return this.rest.post$<{ token: string; refreshToken: string; updatedAt: Date }>('/idam/refreshToken', payload, undefined, 'gateway');
  }

  // getClients() {
  //   return this.rest.get(`${IDAM_URL}/clients`);
  // }


  createNewToken(token: string): Observable<{ token: string; refreshToken: string; createdAt: Date }>{
    const body = {token: token};
    return this.rest.post$<{ token: string; refreshToken: string; createdAt: Date }>('/idam/new-token', body, undefined, 'gateway');
  }

  isLogged(): boolean {
    const tokenValue = this.tokenValue;
    return tokenValue !== undefined && tokenValue !== null && tokenValue !== '';
  }

  logout(): Observable<boolean> {
    if (this.isLogged()) {
      this.memoryStorage.clearAllStorage();
      return this.rest.post$<{ success: boolean }>('/idam/logout', null, undefined, 'gateway').pipe(

        tap(() => {
          localStorage.clear();
          void this.router.navigateByUrl('/login');
        }),
        map(res => res.success),
        catchError(()=>{
          localStorage.clear();
          return EMPTY;
        }),
        finalize(() => {
          localStorage.clear();
          void this.router.navigateByUrl('/login');
        })
      );
    } else {
      localStorage.clear();
      void this.router.navigateByUrl('/login');
      return of(true);
    }


  }

  setToken(token: string, refreshToken: string, updatedAt: Date): void {
    if(!this.updateAt || new Date(updatedAt) > new Date(this.updateAt)){
      localStorage.setItem(CONSTANTS.token, token);
      localStorage.setItem(CONSTANTS.refreshToken, refreshToken);
      localStorage.setItem(CONSTANTS.updatedAt, updatedAt.toString());
    }
  }



  gotoProfile(): void {
    const typeEnviroment = environment.type;
    if (typeEnviroment === 'prod') {
      window.location.href = `https://account.mobilitycompany.it/auth/welcome?token=${this.tokenValue}&refreshToken=${this.refreshTokenValue}`;
    } else {
      window.location.href = `https://account.${typeEnviroment}.mobilitycompany.it/auth/welcome?token=${this.tokenValue}&refreshToken=${this.refreshTokenValue}`;
    }
  }

  getSsoConfig(clientId: string): Observable<OAuth2ConfigOpenOutputDto>{
    return this.rest.get$<OAuth2ConfigOpenOutputDto>(`/idam/client/${clientId}/sso-config-open`, undefined, true , 'gateway');
  }
}
