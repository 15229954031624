
<div *ngIf="calendar" class="col-12 calendar-center">
  <h2>Scegli data e ora</h2>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 flex calendar-container flex-end">
      <mat-calendar [minDate]="minDate"
                    (selectedChange)="onSelectedDateChanged($event)"
                    [startAt]="calendarDate"
                    [selected]="calendarDate"></mat-calendar>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 flex-start">
      <app-timepicker [time]="timeToCheck" (changedTime)="onChangedTime($event)"></app-timepicker>

    </div>
  </div>
</div>
