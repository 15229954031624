import { CdkStepperModule } from '@angular/cdk/stepper';
import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { NgModule, Injectable } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import {
  DateAdapter,
  MatNativeDateModule,
  NativeDateAdapter,
} from '@angular/material/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoaderInterceptorService } from 'app/shared/services/loader-interceptor.service';
import { TokenInterceptor } from 'app/shared/services/token-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadSpinnerComponent } from './shared/components/loadspinner.component';
import { ScegliDataOraModule } from './shared/components/scegli-data-ora/scegli-data-ora.module';
import { WktModule } from './shared/pipes/wkt.module';
import { MatGoogleMapsAutocompleteModule } from 'app/shared/google-maps-autocomplete/mat-google-maps-autocomplete.module';
import { LoadComponent } from './shared/components/load/load.component';

//import { DemoMaterialModule } from './material';

// import { ModalsModule } from 'app/shared/modals/modals.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { generalFeature } from './shared/store/general/general.reducer';
import { GeneralEffects } from './shared/store/general/general.effects';
import { companyFeature } from './shared/store/company/company.reducer';
import { CompanyEffects } from './shared/store/company/company.effects';
import { mapsFeature } from './shared/store/maps/maps.reducer';
import { MapsEffects } from './shared/store/maps/maps.effects';
import { policyDocumentFeature } from './shared/store/policy/policy.document.reducer';
import { PolicyDocumentsEffects } from './shared/store/policy/policy.document.effects';

registerLocaleData(localeIt);
@Injectable()
class CustomDateAdapter extends NativeDateAdapter {
  override getFirstDayOfWeek(): number {
    return 1; // Monday
  }
}

@NgModule({
  declarations: [AppComponent, LoadComponent, LoadSpinnerComponent],
  imports: [
    // ModalsModule,
    ScegliDataOraModule,
    GoogleMapsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // DemoMaterialModule,
    FormsModule,
    MatMenuModule,
    MatDialogModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'it',
    }),
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }), // Non visualizza il warning in console
    // Da Angular 7 in poi sarà deprecato l'NgModel insieme al FormControlName
    // AngularFontAwesomeModule,

    MatNativeDateModule,
    HttpClientModule,
    HttpClientJsonpModule,
    CdkStepperModule,
    MatRadioModule,
    MatIconModule,
    MatTooltipModule,
    WktModule,
    GoogleMapsModule,
    MatGoogleMapsAutocompleteModule,
    MatListModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    StoreModule.forRoot({}, {}),
    StoreModule.forFeature(policyDocumentFeature),
    StoreModule.forFeature(generalFeature),
    StoreModule.forFeature(companyFeature),
    StoreModule.forFeature(mapsFeature),
    EffectsModule.forRoot([
      GeneralEffects,
      CompanyEffects,
      MapsEffects,
      PolicyDocumentsEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http);
}
