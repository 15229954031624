import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { CONSTANTS } from 'app/constants';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

declare interface RequestCustomOptions {
  authorization?: boolean;
  data?: object | null;
  headers?: { [header: string]: string | string[] };
}

const defaultOptions: RequestCustomOptions = {
  authorization: true,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  headers: { Authorization: 'Bearer ' + localStorage.getItem(CONSTANTS.token) },
  data: null,
};

export declare interface Response<T = null> {
  data?: T;
  success: boolean;
  code: number;
}

@Injectable({
  providedIn: 'root',
})
export class RestService {
  request: EventEmitter<boolean> = new EventEmitter<boolean>();

  private mobilityPoolingPath: string;
  private gatewayPath: string;

  constructor(private http: HttpClient) {
    // this.mobilityPoolingPath = environment.NEW_apiUrl;
    this.mobilityPoolingPath = environment.poolingApiUrl;
    this.gatewayPath = environment.apiUrl
      .replace('/mpooling-old', '')
      .replace('/mpooling', '');
  }

  prepareOptions(_options: RequestCustomOptions) {
    this.request.emit(true);
    //TODO: check this
    // for (const optionKey of Object.keys(defaultOptions)) {
    //   if (options[optionKey] === undefined) {
    //     options[optionKey] = defaultOptions[optionKey];
    //   }
    // }
  }

  post(
    url: string,
    data: FormData | object | string | null = null,
    options: RequestCustomOptions = defaultOptions
  ) {
    this.prepareOptions(options);
    return this.http
      .post(
        environment.apiUrl + url,
        data,
        options.authorization ? { headers: options.headers } : {}
      )
      .pipe(finalize(() => this.request.emit(false)));
  }

  post$<T>(
    url: string,
    data: FormData | object | string | null = null,
    options: RequestCustomOptions = defaultOptions,
    baseUrl: 'pooling' | 'policy' | 'gateway' = 'pooling'
  ): Observable<T> {
    this.prepareOptions(options);
    return this.http
      .post<T>(
        (baseUrl === 'pooling'
          ? this.mobilityPoolingPath
          : baseUrl === 'policy'
          ? environment.policyApiUrl
          : this.gatewayPath) + url,
        data,
        options.authorization ? { headers: options.headers } : {}
      )
      .pipe(finalize(() => this.request.emit(false)));
  }

  /**
   * @deprecated DA NON UTILIZZARE PIU'
   * @param url
   * @param data
   * @param options
   * @returns
   */
  put = (
    url: string,
    data: FormData | object | string | null = null,
    options: RequestCustomOptions = defaultOptions
  ) => {
    this.prepareOptions(options);
    return this.http
      .put(
        environment.poolingApiUrl + url,
        data,
        options.authorization ? { headers: options.headers } : {}
      )
      .pipe(finalize(() => this.request.emit(false)));
  };

  /**
   *
   * @param url
   * @param data
   * @param backendType
   */
  put$<T>(
    url: string,
    data: FormData | any | string | unknown = null,
    baseUrl: 'pooling' | 'gateway' = 'pooling'
  ): Observable<T> {
    return this.http
      .put<T>(
        (baseUrl === 'pooling' ? this.mobilityPoolingPath : this.gatewayPath) +
          url,
        data
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  /**
   * @deprecated DA NON UTILIZZARE PIU'
   * @param url
   * @param options
   * @returns
   */
  get = (url: string, options: RequestCustomOptions = defaultOptions) => {
    this.prepareOptions(options);
    // if (options.data) {
    //   url += url.indexOf("?") > -1 ? "&" : "?";
    //   let i = 0;
    //   for (const key of Object.keys(options.data)) {
    //     url +=
    //       key +
    //       "=" +
    //       options.data[key] +
    //       (i === Object.keys(options.data).length - 1 ? "" : "&");
    //     i++;
    //   }
    // }
    return this.http
      .get(
        environment.apiUrl + url,
        options.authorization ? { headers: options.headers } : {}
      )
      .pipe(
        tap((res) => {
          console.log(`please change the call ${url}`, JSON.stringify(res));
        }),
        finalize(() => this.request.emit(false))
      );
  };

  get$<T>(
    url: string,
    options: RequestCustomOptions = defaultOptions,
    directResponse = false,
    baseUrl: 'pooling' | 'policy' | 'gateway' = 'pooling'
  ): Observable<T> {
    this.prepareOptions(options);
    //TODO: check this
    // if (options.data) {
    //   url += url.indexOf("?") > -1 ? "&" : "?";
    //   let i = 0;
    //   for (const key of Object.keys(options.data)) {
    //     url +=
    //       key +
    //       "=" +
    //       options.data[key] +
    //       (i === Object.keys(options.data).length - 1 ? "" : "&");
    //     i++;
    //   }
    // }

    if (directResponse) {
      return this.http
        .get<T>(
          (baseUrl === 'pooling'
            ? this.mobilityPoolingPath
            : baseUrl === 'policy'
            ? environment.policyApiUrl
            : this.gatewayPath) + url,
          options.authorization ? { headers: options.headers } : {}
        )
        .pipe(
          catchError((err) => {
            // this.authService.logout();
            console.error(url, err);
            throw err;
          }),
          finalize(() => this.request.emit(false))
        );
    } else {
      return this.http
        .get<T>(
          (baseUrl === 'pooling'
            ? this.mobilityPoolingPath
            : this.gatewayPath) + url,
          options.authorization ? { headers: options.headers } : {}
        )
        .pipe(
          map((src) => src),
          catchError((err) => {
            // this.authService.logout();
            console.error(url, err);
            throw err;
          }),
          finalize(() => this.request.emit(false))
        );
    }
  }

  /**
   * @deprecated DA NON UTILIZZARE PIU'
   * @param url
   * @returns
   */
  options(url: string) {
    this.request.emit(true);
    return this.http
      .options(environment.apiUrl + url)
      .pipe(finalize(() => this.request.emit(false)));
  }

  deleteEmptyResult$(
    url: string,
    options: RequestCustomOptions = defaultOptions,
    baseUrl: 'pooling' | 'gateway' = 'pooling'
  ): Observable<boolean> {
    this.prepareOptions(options);

    this.request.emit(true);
    return this.http
      .delete(
        (baseUrl === 'pooling' ? this.mobilityPoolingPath : this.gatewayPath) +
          url,
        options.authorization ? { headers: options.headers } : {}
      )
      .pipe(
        map((res) => !!res),
        finalize(() => this.request.emit(false))
      );
  }

  /**
   * @deprecated DA NON UTILIZZARE PIU'
   * @param url
   * @param options
   * @returns
   */
  delete = (url: string, options: RequestCustomOptions = defaultOptions) => {
    this.prepareOptions(options);

    this.request.emit(true);
    return this.http
      .delete(
        environment.poolingApiUrl + url,
        options.authorization ? { headers: options.headers } : {}
      )
      .pipe(finalize(() => this.request.emit(false)));
  };

  /**
   * @deprecated DA NON UTILIZZARE PIU'
   */
  patch = (
    url: string,
    data: FormData | object | string | undefined = undefined,
    options: RequestCustomOptions = defaultOptions
  ) => {
    this.prepareOptions(options);
    return this.http
      .patch(
        environment.poolingApiUrl + url,
        data,
        options.authorization ? { headers: options.headers } : {}
      )
      .pipe(
        tap((res) => {
          console.log('please change patch call ', JSON.stringify(res));
        }),
        finalize(() => this.request.emit(false))
      );
  };
}
