const userClient = 'user_client';
const token = 'token';
const refreshToken = 'refreshToken';
const clientUrl = 'clients';
const company = 'company';
const updatedAt = 'updatedAt';

const maxDistance: number = 2000;
const maxSeconds: number = 1800;



export const CONSTANTS = {
  userClient,
  token,
  updatedAt,
  clientUrl,
  company,
  refreshToken,
  maxDistance,
  maxSeconds
};
