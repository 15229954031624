import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-yn',
  templateUrl: './modal-yn.component.html',
  styleUrls: ['./modal-yn.component.scss'],
  standalone: true,
  imports:[
    CommonModule,
  ]
})
export class ModalYnComponent  {
  title: string = '';
  text: string = '';
  constructor(
    public dialogRef: MatDialogRef<ModalYnComponent>,
    public dialog: MatDialog
  ) {}


  close(choose: boolean): void {
    this.dialogRef.close(choose);
  }
}
