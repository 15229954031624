import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatValidateAddressDirective } from '../directives/address-validator/mat-address-validator.directive';
import { GermanAddress } from '../interfaces';
import { Location } from '../interfaces/location.interface';

import PlaceResult = google.maps.places.PlaceResult;
import AutocompleteOptions = google.maps.places.AutocompleteOptions;
import { selectGoogleMapsWasLoaded } from '../../store/maps/maps.reducer';
import { Store } from '@ngrx/store';


export enum Appearance {
  STANDARD = 'standard',
  FILL = 'fill',
  OUTLINE = 'outline',
  LEGACY = 'legacy',
}

@Component({
  selector: 'mat-google-maps-autocomplete',
  exportAs: 'matGoogleMapsAutocomplete',
  templateUrl: './mat-google-maps-autocomplete.component.html',
  styleUrls: ['./mat-google-maps-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatGoogleMapsAutocompleteComponent),
      multi: true
    }
  ]
})
export class MatGoogleMapsAutocompleteComponent
  implements OnInit, ControlValueAccessor
{
  @ViewChild("search")
  //@ts-ignore
  public searchElementRef: ElementRef;

  @Input()
  addressLabelText = 'Address';

  @Input()
  placeholderText = 'Please enter the address';

  @Input()
  requiredErrorText = 'The address is required';

  @Input()
  invalidErrorText = 'The address is not valid';

  @Input()
  appearance: Appearance = Appearance.STANDARD;

  @Input()
  //@ts-ignore
  value: PlaceResult;

  @Input()
  //@ts-ignore
  address: PlaceResult | string;

  @Input()
  //@ts-ignore
  country: string | string[];

  @Input()
  placeIdOnly?: boolean;

  @Input()
  strictBounds?: boolean;

  @Input()
  types?: string[];
  // types: string[] = ['address'];

  @Input()
  type: string='';

  @Input()
  autoCompleteOptions: AutocompleteOptions = {};

  @Output()
  onChange: EventEmitter<PlaceResult | string | null> = new EventEmitter<PlaceResult | string | null>();

  @Output()
  onAutocompleteSelected: EventEmitter<PlaceResult> = new EventEmitter<PlaceResult>();

  @Output()
  onGermanAddressMapped: EventEmitter<GermanAddress> = new EventEmitter<GermanAddress>();

  @Output()
  onLocationSelected: EventEmitter<Location> = new EventEmitter<Location>();


  private onNewPlaceResult: EventEmitter<any> = new EventEmitter();
  private addressValidator: MatValidateAddressDirective = new MatValidateAddressDirective();

  public addressSearchControl: UntypedFormControl = new UntypedFormControl({value: null}, Validators.compose([
    Validators.required,
    this.addressValidator.validate()])
  );

  propagateChange = (_: any) => {
  };



  constructor(
              private ngZone: NgZone,
              private store: Store) {
  }

  ngOnInit(): void {
    this.addressValidator.subscribe(this.onNewPlaceResult);

    const options: AutocompleteOptions = {
      // types: ['address'],
      // componentRestrictions: {country: this.country},
      placeIdOnly: this.placeIdOnly,
      strictBounds: this.strictBounds,
      // types: this.types,
      types: [this.type]
    };

    // tslint:disable-next-line:no-unused-expression
    this.country ? options.componentRestrictions = {country: this.country} : null;
    // tslint:disable-next-line:no-unused-expression
    this.country ? options.types = this.types : null;

    this.autoCompleteOptions = Object.assign(this.autoCompleteOptions, options);
    this.initGoogleMapsAutocomplete();
  }

  private initGoogleMapsAutocomplete(): void {
    this.store.select(selectGoogleMapsWasLoaded).subscribe((loaded: boolean) => {
      if (loaded) {
        const autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement,
          this.autoCompleteOptions
        );
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            // get the place result
            const place: PlaceResult = autocomplete.getPlace();

            const germanAddress: GermanAddress = {
              gmID: place.place_id,
              icon: place.icon,
              url: place.url,
              placeID: place.place_id,
              displayAddress: place.formatted_address,
              name: place.name,
              vicinity: place.vicinity,
              locality: {},
              state: {},
              country: {},
              geoLocation: { latitude: -1, longitude: -1 },
            };

            if (place.geometry && place.geometry.location) {
              //@ts-ignore
              germanAddress.geoLocation.latitude =
                place.geometry.location.lat();
              //@ts-ignore
              germanAddress.geoLocation.longitude =
                place.geometry.location.lng();
            }
            if(place.address_components) {
            //@ts-ignore
              place.address_components.forEach((value) => {
                if (value.types.indexOf("street_number") > -1) {
                  germanAddress.streetNumber = value.short_name;
                }
                if (value.types.indexOf('route') > -1) {
                  germanAddress.streetName = value.long_name;
                }
                if (value.types.indexOf('postal_code') > -1) {
                  germanAddress.postalCode = Number(value.short_name);
                }
                if (value.types.indexOf('sublocality') > -1) {
                  germanAddress.sublocality = value.long_name;
                }
                if (value.types.indexOf("locality") > -1) {
                  //@ts-ignore
                  germanAddress.locality.long = value.long_name;
                  //@ts-ignore
                  germanAddress.locality.short = value.short_name;
                }
                if (value.types.indexOf("administrative_area_level_1") > -1) {
                  //@ts-ignore
                  germanAddress.state.long = value.long_name;
                  //@ts-ignore
                  germanAddress.state.short = value.short_name;
                }
                if (value.types.indexOf("country") > -1) {
                  //@ts-ignore
                  germanAddress.country.long = value.long_name;
                  //@ts-ignore
                  germanAddress.country.short = value.short_name;
                }
                if (value.types.indexOf("administrative_area_level_3") > -1) {
                  //@ts-ignore
                  germanAddress.locality.short = value.short_name;
                }
              });
            }

            this.onGermanAddressMapped.emit(germanAddress);

            if (!place.place_id || place.geometry === undefined || place.geometry === null) {
              // place result is not valid
              return;
            } else {
              // show dialog to select a address from the input
              // emit failed event
              this.value = place;
              this.propagateChange(this.value);
            }
            //@ts-ignore
            this.address = place.formatted_address;
            this.onAutocompleteSelected.emit(place);
            // console.log('onAutocompleteSelected -> ', place);
            this.onLocationSelected.emit({
              //@ts-ignore
              latitude: place.geometry.location.lat(),
              //@ts-ignore
              longitude: place.geometry.location.lng(),
            });
          });
        });
      }
    });
  }

  public onQuery(_event: any) {
    // console.log('onChange()', event);
    this.onChange.emit(this.address);
  }

  // private resetAddress() {
  //   //@ts-ignore
  //   this.address = null;
  //   this.addressSearchControl.updateValueAndValidity();
  // }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(_fn: any): void {
    throw new Error('Method not implemented.');
  }

  setDisabledState?(_isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

}
